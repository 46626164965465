@font-face {
  font-family: Eurostile;
  src: url("/public/fonts/EUROSTIB.ttf");
}
@font-face {
  font-family: Helvetica;
  src: url("/public/fonts/HelveticaNeue\ Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: Helvetica;
  src: url("/public/fonts/HelveticaNeue-Light.otf");
  font-weight: 400;
}
@font-face {
  font-family: Alarm;
  src: url("/public/fonts/alarm\ clock.ttf");
}
@font-face {
  font-family: TypeWriter;
  src: url("/public/fonts/American\ Typewriter\ Regular.ttf");
}

@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  background-image: url("/public/texture.jpeg");
}
/* Range Slider as a Single Thumb Slider */
.single-thumb .range-slider__thumb[data-lower] {
  width: 0;
}
.single-thumb {
  height: 4px;
  /* margin: 20px; */
  background-color: #403f40;
}
.single-thumb .range-slider__thumb[data-upper] {
  background-image: linear-gradient(
    120deg,
    #8a8f95 20%,
    #8c9198 60%,
    #515358 100%
  );
  background-color: transparent;
  width: 20px;
  height: 20px;
}
.single-thumb .range-slider__range {
  border-radius: 6px;
  background-color: #00a550;
  height: 4px;
}

.handle circle {
  fill: #8c9198;
  position: relative;
  cursor: pointer;
}

.invert-green {
  filter: invert(56%) sepia(64%) saturate(3988%) hue-rotate(115deg)
    brightness(88%) contrast(101%);
}
